import React, { useEffect, useState } from "react";
import { DropDown } from "../../components/DropDown/DropDown";
import { useGeneralInsightsQuery } from "../../hooks/QueryHooks/useGeneralInsights";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { LineChart } from "../../components/Charts/LineChart/LineChart";
import { formatStatDate, pluralize } from "../../tools";
import useMeasure from "react-use-measure";
import "./GeneralStatsBox.css";

export const BroadcastingStatsBox = () => {
    const [chartData, setChartData] = useState(null);
    const [currentPeriod, setCurrentPeriod] = useState("month");
    const [dates, setDates] = useState({});
    const [ref, bounds] = useMeasure();

    const dropDownOptions = [
        {
            label: "lifetime",
            period: "lifetime",
        },
        // {
        //     label: "last 12 months",
        //     period: "year",
        // },
        {
            label: "last month",
            period: "month",
        },
        {
            label: "last week",
            period: "week",
        },
        {
            label: "last day",
            period: "day",
        },
    ];

    const handlePeriod = (option) => {
        const period = dropDownOptions.filter(item => item.label === option)[0].period;
        if (period !== currentPeriod) {
            setCurrentPeriod(period);
        }
    };

    const { insights: broadcastingInsights } = useGeneralInsightsQuery({ period: currentPeriod, dimension: "broadcasting" });

    useEffect(() => {
        if (broadcastingInsights) {
            const dataFrame = broadcastingInsights;

            const startDate = formatStatDate(dataFrame.points[0][0])
            const endDate = formatStatDate(dataFrame.points[dataFrame.points.length - 1][0])
            setDates({ startDate, endDate });

            const data = {
                labels: dataFrame.points.map(point => point[0]),
                datasets: [
                    {
                        label: "hour",
                        data: dataFrame.points.map(point => point[1].toFixed(2)),
                        borderColor: "#A6CEE3",
                        backgroundColor: "rgba(166, 206, 227, 0.4)",
                        tension: 0.4,
                        fill: true,
                        pointRadius: (currentPeriod === "week" || currentPeriod === "year") ? 0 : 3,
                    }
                ],
            };

            setChartData(data);
        }
    }, [broadcastingInsights, currentPeriod]);

    return (
        <div ref={ref} className="general-stats-box">
            {(broadcastingInsights) ? (
                <>
                    {bounds.width < 530 &&
                        <div className="title medium">Broadcasting stats</div>
                    }
                    <div className="top-section">
                        <div className={`title-section ${bounds.width < 530 ? "compact" : ""}`}>
                            {bounds.width >= 530 ? (
                                <>
                                    <div className="title">Broadcasting this period</div>
                                    <p className="subtitle">
                                        {(dates.startDate && dates.endDate ? `${dates.startDate} - ${dates.endDate}` : " ")}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <div className="title">
                                        {broadcastingInsights.total.toLocaleString()}
                                    </div>
                                    <div className="title compact">
                                        {pluralize(broadcastingInsights.total, "hour")}
                                    </div>
                                </>
                            )}
                        </div>
                        <DropDown
                            options={dropDownOptions.map(item => item.label)}
                            defaultOption={dropDownOptions.filter(item => item.period === currentPeriod)[0].label}
                            onSelect={handlePeriod}
                        />
                    </div>

                    <div className="chart">
                        {chartData ? (
                            <LineChart data={chartData} period={currentPeriod} />
                        ) : (
                            <LoaderBox backgroundColor={false} />
                        )}
                    </div>
                </>
            ) : (
                <LoaderBox backgroundColor={false} />
            )}
        </div>
    );
};