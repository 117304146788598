import React, { useEffect, useState } from "react";
import { DropDown } from "../../components/DropDown/DropDown";
import { StatTab } from "../../components/Cards/StatTab/StatTab";
import "./GeneralStatsBox.css";
import { useGeneralInsightsQuery } from "../../hooks/QueryHooks/useGeneralInsights";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { LineChart } from "../../components/Charts/LineChart/LineChart";
import { formatStatDate, pluralize } from "../../tools";
import { Switch } from "../../components/Switch/Switch";
import useMeasure from "react-use-measure";

export const GeneralStatsBox = () => {
    const [chartData, setChartData] = useState(null);
    const [currentDimension, setCurrentDimension] = useState("streams");
    const [currentPeriod, setCurrentPeriod] = useState("month");
    const [dates, setDates] = useState({});
    const [ref, bounds] = useMeasure();

    const dropDownOptions = [
        {
            label: "All-time",
            period: "lifetime",
        },
        // {
        //     label: "last 12 months",
        //     period: "year",
        // },
        {
            label: "Last 28 days",
            period: "month",
        },
        {
            label: "Last 7 days",
            period: "week",
        },
        {
            label: "Latest 24 hours",
            period: "day",
        },
    ];

    const handlePeriod = (option) => {
        const period = dropDownOptions.filter(item => item.label === option)[0].period;
        if (period !== currentPeriod) {
            setCurrentPeriod(period);
        }
    };

    const { insights: streamInsights } = useGeneralInsightsQuery({ period: currentPeriod, dimension: "streams" });
    const { insights: hoursInsights} = useGeneralInsightsQuery({ period: currentPeriod, dimension: "hours" });
    const { insights: broadcastingInsights } = useGeneralInsightsQuery({ period: currentPeriod, dimension: "broadcasting" });

    useEffect(() => {
        let dataFrame = null;
        if (currentDimension === "streams") {
            if (!streamInsights) return;
            dataFrame = streamInsights;
        } else if (currentDimension === "hours") {
            if (!hoursInsights) return;
            dataFrame = hoursInsights;
        } else if (currentDimension === "broadcasting") {
            if (!broadcastingInsights) return;
            dataFrame = broadcastingInsights;
        }

        const startDate = formatStatDate(dataFrame.points[0][0])
        const endDate = formatStatDate(dataFrame.points[dataFrame.points.length - 1][0])
        setDates({ startDate, endDate });

        const data = {
            labels: dataFrame.points.map(point => point[0]),
            datasets: [
                {
                    label: currentDimension === "streams" ? "stream" : "hour",
                    data: dataFrame.points.map(point => currentDimension === "streams" ? point[1] : point[1].toFixed(2)),
                    borderColor: "#A6CEE3",
                    backgroundColor: "rgba(166, 206, 227, 0.4)",
                    tension: 0.4,
                    fill: true,
                    pointRadius: (currentPeriod === "week" || currentPeriod === "year") ? 0 : 3,
                }
            ],
        };

        setChartData(data);
    }, [streamInsights, hoursInsights, broadcastingInsights, currentDimension, currentPeriod]);

    return (
        <div ref={ref} className="general-stats-box">
            {(streamInsights && hoursInsights && broadcastingInsights) ? (
                <>
                    {bounds.width < 530 &&
                        <Switch options={[
                            {
                                name: "streams",
                                onClick: () => setCurrentDimension("streams"),
                            },
                            {
                                name: "hours",
                                onClick: () => setCurrentDimension("hours"),
                            },
                            {
                                name: "broadcasting",
                                onClick: () => setCurrentDimension("broadcasting"),
                            },
                        ]} defaultOption={currentDimension} />
                    }
                    <div className="top-section">
                        <div className={`title-section ${bounds.width < 530 ? "compact" : ""}`}>
                            {bounds.width >= 530 ? (
                                <>
                                    <div className="title">Stats this period</div>
                                    <p className="subtitle">
                                        {(dates.startDate && dates.endDate ? `${dates.startDate} - ${dates.endDate}` : " ")}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <div className="title">
                                        {currentDimension === "streams" && streamInsights.total.toLocaleString()}
                                        {currentDimension === "hours" && hoursInsights.total.toLocaleString()}
                                        {currentDimension === "broadcasting" && broadcastingInsights.total.toLocaleString()}
                                    </div>
                                    <div className="title compact">
                                        {currentDimension === "streams" && pluralize(streamInsights.total, "stream")}
                                        {currentDimension === "hours" && pluralize(hoursInsights.total, "hour")}
                                        {currentDimension === "broadcasting" && pluralize(hoursInsights.total, "hour")}
                                    </div>
                                </>
                            )}
                        </div>
                        <DropDown
                            options={dropDownOptions.map(item => item.label)}
                            defaultOption={dropDownOptions.filter(item => item.period === currentPeriod)[0].label}
                            onSelect={handlePeriod}
                        />
                    </div>
                    {bounds.width >= 530 &&
                        <div className="tabs">
                            <StatTab
                                key="streams"
                                label="Streams"
                                value={streamInsights.total.toLocaleString()}
                                active={"streams" === currentDimension}
                                onClick={() => setCurrentDimension("streams")}
                            />
                            <StatTab
                                key="hours"
                                label="Hours"
                                value={hoursInsights.total.toLocaleString()}
                                active={"hours" === currentDimension}
                                onClick={() => setCurrentDimension("hours")}
                            />
                            <StatTab
                                key="broadcasting"
                                label="Broadcasting"
                                value={broadcastingInsights.total.toLocaleString()}
                                active={"broadcasting" === currentDimension}
                                onClick={() => setCurrentDimension("broadcasting")}
                            />
                        </div>
                    }

                    <div className="chart">
                        {chartData ? (
                            <LineChart data={chartData} period={currentPeriod} />
                        ) : (
                            <LoaderBox backgroundColor={false} />
                        )}
                    </div>
                </>
            ) : (
                <LoaderBox backgroundColor={false} />
            )}
        </div>
    );
};