import { createContext, useState, useEffect, useRef } from "react";
import { AlertOverlay } from "../components/Overlays/AlertOverlay/AlertOverlay";
import { AnimatePresence, motion } from "framer-motion";
import { CuratorRequestOverlay } from "../components/Overlays/CuratorRequestOverlay/CuratorRequestOverlay";
import { ProfileDeletionOverlay } from "../components/Overlays/ProfileDeletionOverlay/ProfileDeletionOverlay";

const overlayStyle = {
    position: "fixed",
    willChange: "unset",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "hsla(239, 32%, 25%, 0.4)"
};

export const OverlayContext = createContext(null);

export const OverlayProvider = ({ children }) => {
    const [displayModal, setDisplayModal] = useState(false);
    const [modal, setModal] = useState(null);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [info, setInfo] = useState({
        curatorRequest: {},
        profileDeletion: {},
        details: "",
        onClose: () => {},
    });
    const timerRef = useRef(null);

    const DisplayAlertCallback = (newDetails, title = null, color = null, onClose = () => {}) => {
        setInfo({
            ...info, 
            details: newDetails,
            title,
            color,
            onClose,
        });
        setDisplayAlert(true);
    };

    const DisplayModalCallback = (modalName, modalInfo = {}) => {
        setInfo({...info, [modalName]: modalInfo})
        setModal(modalName);
        setDisplayModal(true);
    };

    useEffect(() => {
        if (displayAlert) {
            timerRef.current = setTimeout(() => {
                setDisplayAlert(false);
                info.onClose();
                setInfo({
                    ...info, 
                    details: "",
                    title: null,
                    color: null,
                    onClose: () => {},
                });
            }, 5000);
        }
        return () => clearTimeout(timerRef.current);
    }, [displayAlert, info]);

    const handleAlertClose = () => {
        setDisplayAlert(false);
        info.onClose();
        setInfo({
            ...info, 
            details: "",
            title: null,
            color: null,
            onClose: () => {},
        });
        clearTimeout(timerRef.current);
    };

    const handleModalClose = (modalName) => {
        setModal(null);
        setDisplayModal(false);
        setInfo({...info, [modalName]: {}})
    };

    return (
        <OverlayContext.Provider value={{ DisplayAlertCallback, DisplayModalCallback, displayModal }}>
            <AnimatePresence>
                {children}
                {displayModal &&
                    <motion.div
                        key="overlayBackground"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.45 }}
                        style={overlayStyle}
                    />}
                {modal === "curatorRequest" && displayModal && <CuratorRequestOverlay key="curatorRequestOverlay" requestInfo={info.curatorRequest} onClose={() => handleModalClose("curatorRequest")} />}
                {modal === "profileDeletion" && displayModal && <ProfileDeletionOverlay key="profileDeletion" requestInfo={info.profileDeletion} onClose={() => handleModalClose("profileDeletion")} />}
                {displayAlert && <AlertOverlay key="alertOverlay" title={info.title} details={info.details} color={info.color} onClose={handleAlertClose}/>}
            </AnimatePresence>
        </OverlayContext.Provider>
    );
};