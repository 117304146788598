import React, { useContext, useState } from "react";
import "./ProfileDeletionOverlay.css";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { useOutsideClickDetector } from "../../../hooks/useOutsideClickDetector";
import { motion } from "framer-motion";
import { LoaderBox } from "../../../blocks/LoaderBox/LoaderBox";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";

export const ProfileDeletionOverlay = ({ requestInfo, onClose }) => {
    const [username, setUsername] = useState("");
    const [isPending, setIsPending] = useState(false);
    const { isTablet, isDesktop } = useContext(ResponsiveContext);
    const outsideDetector = useOutsideClickDetector(() => {
        onClose();
        requestInfo.onSelect();
    });
    const { deleteProfile } = requestInfo.deleteProfileHook;

    const handleChange = (item) => {
        setUsername(item.target.value);
    };

    const handleDeleteRequest = () => {
        setIsPending(true);
        deleteProfile(
            () => {
                requestInfo.onSelect();
                onClose();
                setIsPending(false);
                requestInfo.DisplayAlertCallback(
                    "You will be redirected to the home page",
                    "Your profile has been deleted",
                    "#94E9DE",
                    requestInfo.signOut,
                );
            },
            () => {
                requestInfo.onSelect();
                onClose();
                setIsPending(false);
            });
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.45 }}
            style={{ willChange: "unset" }}>

            <div ref={outsideDetector} className="profile-deletion">
                <header className="header">
                    <div className="text-wrapper">Warning: Irreversible Action</div>
                    <CloseButton onClick={() => {
                        onClose();
                        requestInfo.onSelect();
                    }} />
                </header>
                <div className="info">
                    <p className="div">
                        Deleting your profile is permanent and cannot be undone.
                        <br />
                        This action will result in the following:
                    </p>
                    <div className="div">
                        <ul>
                            <li>All data associated with your profile will be permanently deleted.</li>
                            <li>Your SFScoop tokens will be lost and cannot be recovered.</li>
                            <li>Any connected external services will be disconnected.</li>
                            {["manager", "artist", "label"].includes(requestInfo.profileInfo.user_role) &&
                                <li>You will lose all rights to your claimed artist profiles.</li>
                            }
                        </ul>
                    </div>
                </div>
                <div className="confirmation">
                    <div className="username-box">
                        <p className="div">Enter your username to proceed:</p>
                        <div className="text-wrapper-2">{requestInfo.profileInfo.username}</div>
                    </div>
                    <div className="input-box">
                        <input
                            id="username"
                            type="text"
                            value={username}
                            onChange={handleChange}
                            className="text-box-wrapper"
                            placeholder={requestInfo.profileInfo.username} />
                        {(isTablet || isDesktop) &&
                            <PrimaryButton
                                disabled={username !== requestInfo.profileInfo.username || isPending}
                                disableStyle="fade"
                                onClick={handleDeleteRequest}
                            >
                                {isPending ? <LoaderBox width="24" height="24" box={false} /> : "Delete profile"}
                            </PrimaryButton>
                        }
                    </div>
                </div>
                {!(isTablet || isDesktop) &&
                    <PrimaryButton
                        disabled={username !== requestInfo.profileInfo.username || isPending}
                        disableStyle="fade"
                        onClick={handleDeleteRequest}
                    >
                        {isPending ? <LoaderBox width="24" height="24" box={false} /> : "Delete profile"}
                    </PrimaryButton>
                }
            </div>
        </motion.div>
    );
};