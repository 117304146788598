import PropTypes from "prop-types";
import React, { useContext } from "react";
import { SideMenuButton, VARIANTS } from "../../components/Buttons/SideMenuButton/SideMenuButton";
import { absolutePaths } from "../../routes";
import { SigmundContext } from "../../contexts/SigmundContext";
import { SideMenuCompositeButton } from "../../components/Buttons/SideMenuCompositeButton/SideMenuCompositeButton";
import "./SettingsMenu.css";
import { OverlayContext } from "../../contexts/OverlayContext";
import { useSignOut } from "../../hooks/QueryHooks/useSignOut";
import { useDeleteProfile } from "../../hooks/QueryHooks/useDeleteProfile";

export const SettingsMenu = ({ onSelect, hide = () => {} }) => {
    const { profileInfo } = useContext(SigmundContext);
    const { DisplayModalCallback, DisplayAlertCallback } = useContext(OverlayContext);
    const deleteProfileHook = useDeleteProfile();
    const signOut = useSignOut();

    return (
        <div className="settings-menu">
            {(profileInfo.user_role === "manager") &&
                <>
                    <SideMenuCompositeButton variant={VARIANTS.myArtists}>
                        <SideMenuButton disabled={!profileInfo.artist_claim_available} to={absolutePaths.claimArtist} variant={VARIANTS.claimArtist} onClick={onSelect} style={{ height: 32 }} />
                    </SideMenuCompositeButton>
                </>
            }
            <SideMenuCompositeButton variant={VARIANTS.profile}>
                <SideMenuButton to={absolutePaths.updateUsername} variant={VARIANTS.updateUsername} onClick={onSelect} style={{ height: 32 }} />
                <SideMenuButton
                    to={"#"}
                    variant={VARIANTS.deleteProfile}
                    onClick={() => {
                        hide();
                        DisplayModalCallback("profileDeletion", {
                            profileInfo,
                            deleteProfileHook,
                            onSelect,
                            signOut,
                            DisplayAlertCallback,
                        })
                    }}
                    style={{ height: 32 }} />
            </SideMenuCompositeButton>
        </div>
    );
};

SettingsMenu.propTypes = {
    onSelect: PropTypes.func,
};

SettingsMenu.defaultProps = {
    onSelect: () => { },
};