import React from "react";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import { motion } from "framer-motion";
import "./AlertOverlay.css";

export const AlertOverlay = ({ onClose, details, title = null, color = null }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="alert-card" style={color ? {backgroundColor: color} : null}>
        <div className="warning-text">
          <div className="text-wrapper">{title ? title : "Oops. Something went wrong"}</div>
          <div className="div">{details}</div>
        </div>
        <div className="close-box">
          <CloseButton onClick={onClose} />
        </div>
      </div>
    </motion.div>
  );
};