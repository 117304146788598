import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import { StockCard } from "../../Cards/StockCard/StockCard";
import { Counter } from "../../Counter/Counter";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { InfoButton } from "../../Buttons/InfoButton/InfoButton";
import { Link } from "react-router-dom";
import { absolutePaths } from "../../../routes";
import { CheckBox } from "../../CheckBox/CheckBox";
import { AnimatePresence, motion } from "framer-motion";
import { useOutsideClickDetector } from "../../../hooks/useOutsideClickDetector";
import { LoaderBox } from "../../../blocks/LoaderBox/LoaderBox";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";
import "./CuratorRequestOverlay.css";

export const CuratorRequestOverlay = ({ requestInfo, onClose }) => {
    const { isTablet, isDesktop } = useContext(ResponsiveContext);
    const [openInfo, setOpenInfo] = useState(false);
    const [saveTemplate, setSaveTemplate] = useState(false);
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const [tokens, setTokens] = useState(requestInfo.profileInfo.tokens);
    const [requests, setRequests] = useState(0);
    const [text, setText] = useState(requestInfo.template);
    const outsideDetector = useOutsideClickDetector(onClose);
    const [isPending, setIsPending] = useState(false);
    const { SendCuratorRequestQuery } = requestInfo.curatorRequestHook;

    useEffect(() => {
        if (tokens - requestInfo.request_token_price < 0) {
            setInsufficientBalance(true);
        } else {
            setInsufficientBalance(false);
        }
    }, [tokens, requestInfo.request_token_price]);

    const handleCounterChange = (value) => {
        setRequests(prev => prev + value);
        setTokens(prev => prev - requestInfo.request_token_price * value);
    };

    const handleCheckBoxChange = (value) => {
        setSaveTemplate(value);
    };

    const handleSendRequest = () => {
        setIsPending(true);
        SendCuratorRequestQuery(
            requests,
            text,
            saveTemplate,
            requestInfo.currentProfile.artist_id,
            () => {
                requestInfo.onSend();
                onClose();
                setIsPending(false);
            },
            () => {
                setIsPending(false);
            },
        )
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.45 }}
            style={{willChange: "unset"}}
        >
            <div ref={outsideDetector} className="curator-request-overlay">
                <div className="section">
                    <div className="title">Request template</div>
                    <CloseButton onClick={onClose} />
                </div>
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="text-box text-wrapper" />
                <div className="bottom-section">
                    <div className="settings">
                        <div className="stock-section">
                            <div className="stock">
                                <span className={`text-wrapper ${insufficientBalance ? "warning" : ""}`}>
                                    {insufficientBalance ? "Insufficient" : "Available"}
                                </span>
                                <StockCard label="tokens" value={tokens} warning={insufficientBalance} />
                            </div>
                            <Counter
                                type={(isTablet || isDesktop) ? "desktop" : "mobile"}
                                count={requests}
                                onChange={handleCounterChange}
                                limitValue={requestInfo.requests_available}
                                disableIncr={insufficientBalance} />
                        </div>
                        {(isTablet || isDesktop) &&
                            <PrimaryButton
                                disabled={!requests || isPending}
                                disableStyle="fade"
                                onClick={handleSendRequest}
                            >
                                {isPending ?
                                    <LoaderBox width="24" height="24" box={false} /> : "Send Requests"}
                            </PrimaryButton>
                        }
                    </div>
                    {!(isTablet || isDesktop) &&
                        <PrimaryButton
                            disabled={!requests || isPending}
                            disableStyle="fade"
                            onClick={handleSendRequest}
                        >
                            {isPending ?
                                <LoaderBox width="24" height="24" box={false} /> : "Send Requests"}
                        </PrimaryButton>
                    }
                    <div className="additional-section">
                        <div style={{ marginBottom: (isTablet || isDesktop) ? "4px" : "16px" }} className="section">
                            <InfoButton onClick={() => setOpenInfo(prev => !prev)} />
                            <div className="save-template">
                                <div className="text-wrapper">Save template</div>
                                <CheckBox onCheck={handleCheckBoxChange} />
                            </div>
                        </div>
                        <AnimatePresence>
                            {openInfo &&
                                <motion.div
                                    style={{ overflow: "hidden" }}
                                    initial={{ height: 0 }}
                                    animate={{ height: "auto" }}
                                    transition={{ duration: 0.45 }}
                                    exit={{ height: 0 }}
                                    key="infoSection"
                                    className="info-section">
                                    <p className="text-wrapper info">
                                        Sending 1 request costs {requestInfo.request_token_price} tokens. Earn tokens to send more requests to curators. You can send a maximum
                                        of {requestInfo.requests_month_limit} requests per month.
                                    </p>
                                    <Link to={absolutePaths.faq} target="_blank" rel="noopener noreferrer" className="link">How to earn tokens?</Link>
                                </motion.div>
                            }
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

CuratorRequestOverlay.propTypes = {
    type: PropTypes.oneOf(["desktop", "mobile"]),
};