import { DeleteProfileMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useDeleteProfile = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const queryMutation = DeleteProfileMutation();

    const deleteProfile = (onComplete = () => { }, onErrorEvent = () => { }) => {
        queryMutation.mutate({}, {
            onSuccess: () => {
                onComplete();
            },
            onError: (error) => {
                onErrorEvent();
                queryErrorHandler(error);
            }
        })
    };

    return { deleteProfile, queryMutation };
};